import { Controller } from '@hotwired/stimulus'

import { useLocalTimeClock } from '../src/mixins/cesium/use_local_time_clock'

import { useAdsbLayer } from '../src/mixins/cesium/use_adsb_layer'
import { useAdsbDataSourceTable } from '../src/mixins/cesium/use_adsb_data_source_table'

import { SeparationCylinder } from '../src/mixins/cesium/separation_cylinder'

import { withCameraOrbiter } from '../src/mixins/cesium/with_camera_orbiter'
import { withDockMarker } from '../src/mixins/cesium/with_dock_marker'
import { DroneMarker } from '../src/mixins/cesium/with_drone_marker'
import { withHubMarker } from '../src/mixins/cesium/with_hub_marker'
import { withCompass } from '../src/mixins/cesium/with_compass'

export default class extends Controller {
  static values = {
    accessToken: String,
    dockLatitude: Number,
    dockLongitude: Number,
    hubLatitude: Number,
    hubLongitude: Number,
    adsbUrl: String
  }

  connect () {
    this.viewerInitialized = false
    this.dockMarker = null
    this.droneMarker = null
    this.hubMarker = null

    this.separationEntity = null
    this.droneEntity = null
    this.dockEntity = null
    this.hubEntity = null

    this.#cesiumInit()

    // Initialize the Hub location if it is available in Curo already.
    this.hubInit()
    this.dockInit()
  }

  #cesiumInit () {
    // Basic Functions
    Cesium.Ion.defaultAccessToken = this.accessTokenValue

    this.viewer = new Cesium.Viewer(this.element, {
      shouldAnimate: true,
      fullscreenElement: this.element
    })
    this.toolbar = this.createToolbar()
    this.beautifyCredit()

    this.cameraInitialized = false
    this.viewerInitialized = true

    this.homeOverride = (e) => {
      e.cancel = true
      this.zoomToEntities()
    }

    this.viewer.homeButton.viewModel.command.beforeExecute.addEventListener(this.homeOverride)

    useLocalTimeClock(this, { cesiumViewer: this.viewer })

    // Extended functions
    // Orbit around the Drone whenever the camera is not being moved
    this.droneOrbiter = withCameraOrbiter(this, { cesiumViewer: this.viewer })

    useAdsbLayer(this, { cesiumViewer: this.viewer, toolbar: this.toolbar })
    useAdsbDataSourceTable(this, { cesiumViewer: this.viewer, dataSourceId: 'adsb-data-source', toolbar: this.toolbar })

    this.separationCylinder = new SeparationCylinder(
      this, {
        cesiumViewer: this.viewer,
        toolbar: this.toolbar
      }
    )

    this.hubMarker = withHubMarker(this, { cesiumViewer: this.viewer })
    this.dockMarker = withDockMarker(this, { cesiumViewer: this.viewer })
    this.droneMaker = new DroneMarker(this.viewer,
      {
        droneId: 'drone',
        droneName: 'Drone'
      }
    )

    withCompass(this, { cesiumViewer: this.viewer })
  }

  disconnect () {
    if (this.viewer) {
      this.viewer.homeButton.viewModel.command.beforeExecute.removeEventListener(this.homeOverride)

      this.viewer.destroy()
    }
  }

  hubInit () {
    // Draw the Hub location
    if (this.hasHubLatitudeValue && this.hasHubLongitudeValue) {
      this.hubMarker.update({ name: 'Hub', latitude: this.hubLatitudeValue, longitude: this.hubLongitudeValue })

      this.hubEntity = this.hubMarker.getEntity()
      this.refreshSeparations()

      // Zoom in and Orbit around the Hub until the Drone has a position

      this.zoomToEntities().then(() => {
        this.droneOrbiter.setTarget({ latitude: this.hubLatitudeValue, longitude: this.hubLongitudeValue })
        this.droneOrbiter.enable()
      })
    }
  }

  dockInit () {
    // Draw the Dock location
    if (this.hasDockLatitudeValue && this.hasDockLongitudeValue) {
      // Update the Dock Marker position
      this.dockMarker.update({ name: 'Dock', latitude: this.dockLatitudeValue, longitude: this.dockLongitudeValue })

      this.dockEntity = this.dockMarker.getEntity()
      this.refreshSeparations()
    }
  }

  beautifyCredit () {
    this.viewer.scene.postRender.addEventListener((_scene, _time) => {
      $(this.element).find('.cesium-credit-logoContainer a').html('Imagery: Cesium ION. ')
    })
  }

  startPlayback () {
    if (this.earliestTimestamp) {
      // start the clock animation
      this.viewer.clock.shouldAnimate = true
    }
  }

  // Update the Drone Position
  // eslint-disable-next-line camelcase
  updateDrone ({ detail: { drone_status: { position, elevation }, meta: { last_timestamp } } }) {
    if (position === null) return

    // Ignore if the drone time is too far in the past
    // eslint-disable-next-line camelcase
    const diffInSeconds = Math.abs((new Date(this.viewer.clock.currentTime).getTime() - last_timestamp) / 1000)
    if (diffInSeconds > 60) return

    // Redraw the Drone Position
    this.droneMaker.addPosition(position.latitude, position.longitude, elevation, last_timestamp).then(() => {
      // Set the drone entity if we haven't
      if (!this.droneEntity) {
        this.droneEntity = this.droneMaker.entity
        this.refreshSeparations()
      }
    })
  }

  // eslint-disable-next-line camelcase
  updateDock ({ detail: { dock_status: { serial_number, position } } }) {
    if (position === null || !this.dockMaker) return

    // eslint-disable-next-line camelcase
    const dockName = 'Dock: ' + serial_number

    if (this.hasDockLatitudeValue && this.hasDockLongitudeValue) {
      // Update the Dock Label (keep the Dock Marker in the same place as HubX)
      this.dockMarker.update({
        name: dockName,
        latitude: this.dockLatitudeValue,
        longitude: this.dockLongitudeValue
      })
    } else if (position && position.latitude && position.longitude) {
      // Move the Dock Marker to the Current Dock position
      this.dockMarker.update({
        name: dockName,
        latitude: position.latitude,
        longitude: position.longitude
      })
    }

    if (!this.dockEntity) {
      this.dockEntity = this.viewer.entities.getById('Dock')
      this.refreshSeparations()
    }
  }

  // eslint-disable-next-line camelcase
  updateHub ({ detail: { hub_x_status: { serial_number, router } } }) {
    if (!router || !this.hubMarker) return

    // eslint-disable-next-line camelcase
    const hubName = 'Hub: ' + serial_number

    if (router && router.latitude && router.longitude) {
      this.hubMarker.update({
        name: hubName,
        latitude: router.latitude,
        longitude: router.longitude
      })
    }

    if (!this.hubEntity) {
      this.hubEntity = this.hubMarker.getEntity()
      this.refreshSeparations()
    }
  }

  refreshSeparations () {
    this.separationEntity = this.droneEntity || this.dockEntity

    this.setAdsbTableSeparationTarget(this.separationEntity)
    this.separationCylinder.setSepEntity(this.separationEntity)
  }

  zoomToDataSource () {
    this.viewer.flyTo(this.droneDataSource, {
      duration: 2,
      maximumHeight: 15
      // offset: new Cesium.HeadingPitchRange(0.0, -Cesium.Math.PI_OVER_TWO, 10000)
    })
  }

  zoomToEntities () {
    return this.viewer.zoomTo(this.viewer.entities, new Cesium.HeadingPitchRange(0.0, -Cesium.Math.PI_OVER_SIX, 1000))
  }

  // Refactor this into an external module
  createToolbar () {
    const viewerDom = $(this.viewer.container).find('.cesium-viewer')

    const toolbar = $('<div/>', { class: 'cesium-viewer-toolbar-container' })
    viewerDom.append(toolbar)

    return toolbar
  }
}
