import { Controller } from 'stimulus'

import { createConsumer } from '@rails/actioncable'

const deepGet = (obj, keys) => keys.reduce((xs, x) => xs?.[x] ?? null, obj)

export default class extends Controller {
  static classes = [
    'error'
  ]

  static targets = [
    'restUrl',
    'cableUrl',
    'cableStatus',
    'cableReceived',
    'channel',
    'restOutput',
    'cableOutput']

  static values = {
    restUrl: String,
    cableUrl: String,
    channelPath: Array,
    cableReceived: {
      type: Number,
      default: 0
    }
  }

  connect () {
    this.restUrlTarget.textContent = this.restUrlValue
    this.cableUrlTarget.textContent = this.cableUrlValue

    setTimeout(() => {
      this.fetchRest()
    }, 0)
  }

  async fetchRest () {
    const response = await fetch(this.restUrlValue)

    const data = await response.json()
    this.restOutputTarget.textContent = JSON.stringify(data)
    this.restOutputTarget.classList.toggle(this.errorClass, !response.ok)

    const channelParams = deepGet(data, this.channelPathValue)
    if (response.ok && channelParams) {
      this.channelTarget.textContent = JSON.stringify(channelParams)

      setTimeout(() => {
        this.connectCable(channelParams)
      }, 0)
    }
  }

  async connectCable (channelParams) {
    // Create the consumer
    this.cableStatusTarget.textContent = 'Connecting...'
    const consumer = createConsumer(this.cableUrlValue)

    // Connect to the channel
    consumer.subscriptions.create(channelParams, {
      received: (data) => {
        this.cableReceivedValue++
        this.cableReceivedTarget.textContent = this.cableReceivedValue
        this.cableOutputTarget.textContent = JSON.stringify(data)
      },

      // Called when the subscription is ready for use on the server.
      connected: () => {
        this.cableStatusTarget.textContent = 'Connected'
        this.cableStatusTarget.classList.toggle(this.errorClass, false)
      },

      // Called when the subscription is disconnected by the server.
      disconnected: () => {
        this.cableStatusTarget.textContent = 'Disconnected'
        this.cableStatusTarget.classList.toggle(this.errorClass, true)
      },

      // Called when the subscription is rejected by the server.
      rejected: () => {
        this.cableStatusTarget.textContent = 'Rejected'
        this.cableStatusTarget.classList.toggle(this.errorClass, true)
      }
    })
  }
}
