import CompassOuter from '../../../images/compass-outer.svg'
import CompassInner from '../../../images/compass-inner.svg'

// Inspired by our Curo ROC project compass:
// https://github.com/sphere-drones/curo-roc/blob/develop/components/Map/Compass.vue

class CesiumCompass {
  constructor (viewer) {
    this.viewer = viewer

    // Build compass components
    this.compassAngleDiv = this.buildCompassAngleDiv()
    this.compassOrientationDiv = this.buildCompassOrientationDiv()
    this.compassOuterCircleImg = this.buildCompassOuterCircleImg()

    // Build compass
    const compassDiv = this.buildCompassDiv(this.compassOuterCircleImg, this.compassAngleDiv, this.compassOrientationDiv)

    // Add the compass to the map
    const viewerDiv = viewer.container.querySelector('.cesium-viewer')
    viewerDiv.appendChild(compassDiv)

    this.removeCompassListener = viewer.scene.postRender.addEventListener(this.onCameraChanged)
  }

  onCameraChanged = () => {
    const cameraAngle = Cesium.Math.toDegrees(this.viewer.camera.heading)

    const normalizedAngle = cameraAngle < 0 || cameraAngle >= 360 ? 0 : cameraAngle
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW']
    const index = Math.floor((normalizedAngle + 22.5) / 45) % 8

    const displayAngle = Math.round(normalizedAngle) % 360
    const displayOrientation = directions[index]

    this.compassAngleDiv.innerText = displayAngle + '°'
    this.compassOrientationDiv.innerText = displayOrientation
    this.compassOuterCircleImg.style.transform = `rotate(${-normalizedAngle}deg)`
  }

  buildCompassDiv (compassOuterCircleImg, compassAngleDiv, compassOrientationDiv) {
    const compassDiv = document.createElement('div')
    compassDiv.classList.add('cesium-viewer-compassContainer', 'position-absolute')

    const centerCompassDiv = document.createElement('div')
    centerCompassDiv.id = 'center-compass'
    centerCompassDiv.classList.add('position-absolute', 'center-compass')

    const compassInnerImg = document.createElement('img')
    compassInnerImg.id = 'compass-inner'
    compassInnerImg.src = CompassInner

    const textCenterDiv = document.createElement('div')
    textCenterDiv.classList.add('position-absolute', 'center-text')

    textCenterDiv.appendChild(compassOrientationDiv)
    textCenterDiv.appendChild(compassAngleDiv)

    centerCompassDiv.appendChild(compassInnerImg)
    centerCompassDiv.appendChild(textCenterDiv)

    compassDiv.appendChild(compassOuterCircleImg)
    compassDiv.appendChild(centerCompassDiv)

    return compassDiv
  }

  buildCompassAngleDiv () {
    const angleDiv = document.createElement('div')
    angleDiv.id = 'compass-angle'
    angleDiv.innerText = '-'

    return angleDiv
  }

  buildCompassOrientationDiv () {
    const orientationDiv = document.createElement('div')
    orientationDiv.id = 'compass-orientation'
    orientationDiv.innerText = '-'

    return orientationDiv
  }

  buildCompassOuterCircleImg () {
    const compassOuterImg = document.createElement('img')
    compassOuterImg.src = CompassOuter

    return compassOuterImg
  }
}

export const withCompass = (controller, { cesiumViewer }) => {
  const compass = new CesiumCompass(cesiumViewer)

  const controllerDisconnect = controller.disconnect.bind(controller)
  Object.assign(controller, {
    disconnect () {
      compass.removeCompassListener()
      controllerDisconnect()
    }
  })
}
