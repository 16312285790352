import Uppy from '@uppy/core'
import XHR from '@uppy/xhr-upload'
import AwsS3 from '@uppy/aws-s3'

export function uppyS3StorageInstance (id) {
  const uppy = uppyBasicInstance(id)
  uppy.use(AwsS3, {
    // shouldUseMultipart defaults to multipart for files over 100 MB
    companionUrl: '/' // will call Shrine's presign endpoint mounted on `/s3/params`
  })
  return uppy
}

export function uppyLocalStorageInstance (id) {
  const uppy = uppyBasicInstance(id)
  uppy.use(XHR, {
    endpoint: '/upload' // Shrine's upload endpoint
  })
  return uppy
}

function uppyBasicInstance (id) {
  const uppy = new Uppy({
    id,
    autoProceed: true,
    restrictions: {
    }
  })
  return uppy
}

export function uploadedS3FileData (file, response) {
  const id = file.meta.key.match(/^uploads_cache\/(.+)/)[1] // object key without prefix
  return JSON.stringify(fileData(file, id))
}

export function uploadedLocalFileData (file, response) {
  return JSON.stringify(response.body)
}

// constructs uploaded file data in the format that Shrine expects
function fileData (file, id) {
  return {
    id,
    storage: 'cache',
    metadata: {
      size: file.size,
      filename: file.name,
      mime_type: file.type
    }
  }
}
